import {
  Combo,
  enumToComboOptionTransformer,
  NumericInput,
} from '../../../core/componet'
import { translate } from '../../../core'
import { Col, DatePicker, Divider, Form, Input, Row } from 'antd'
import { StoneShape } from '../type'
import { Uploader } from '../../../core/componet/Upload'
import { ItemFormVrnPackage } from './ItemFormVrnPackage'
import { stoneClarityItems, stoneColors, stoneNames } from '../model-mapper/item-combe-mapper'
import { dateFormat } from '../../../core/until'
import { InputCard } from './InputCard'
import styles from './ItemEntry.module.scss'

export const ItemFormStone = () => {
  return (
    <InputCard className='mt-0' title={translate('stone')}>
      <Row gutter={[10, 10]}>
        <Col span={6}>
          <Form.Item className={styles['stones-input']} label={translate('name')} name={['stone', 'name']}>
            <Combo size='middle' options={stoneNames}/>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item className={styles['stones-input']} label={translate('parcel_number')}
                     name={['stone', 'parcelNumber']}>
            <Input style={{width: '100%'}}/>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={6}>
          <Form.Item className={styles['stones-input']}
                     style={{marginBottom: 0}}
                     label={translate('average_weight')}
                     name={['stone', 'averageWeight']}
          >
            <NumericInput/>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item className={styles['stones-input']} label={translate('number_of_pieces')}
                     name={['stone', 'numberOfPieces']}>
            <NumericInput decimalPlaces={0}/>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item className={styles['stones-input']} label={translate('total_weight')}
                     name={['stone', 'totalWeight']}>
            <NumericInput/>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item className={styles['stones-input']} label={translate('make')} name={['stone', 'make']}>
            <Input/>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={6}>
          <Form.Item className={styles['stones-input']}
                     label={translate('shape')}
                     name={['stone', 'shape']}
          >
            <Combo
              size='middle'
              options={enumToComboOptionTransformer(StoneShape, ([a], [b]) => {
                if (a.startsWith('Round'))
                  return -1
                if (b.startsWith('Round'))
                  return 1
                return a.localeCompare(b)
              })}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item className={styles['stones-input']} label={translate('clarity')} name={['stone', 'clarity']}>
            <Combo size='middle' options={stoneClarityItems}/>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item className={styles['stones-input']} label={translate('color')} name={['stone', 'color']}>
            <Combo size='middle' options={stoneColors}/>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item className={styles['stones-input']} label={translate('cut')} name={['stone', 'cut']}>
            <Input/>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={6}>
          <Form.Item className={styles['stones-input']} label={translate('cost_ct')} name={['stone', 'costCT']}>
            <NumericInput/>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item className={styles['stones-input']} label={translate('rap')} name={['stone', 'rap']}>
            <NumericInput/>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item className={styles['stones-input']} label={translate('%', 'off')} name={['stone', 'offPercent']}>
            <NumericInput/>
          </Form.Item>
        </Col>
      </Row>

      <Divider style={{marginBottom: 2}}/>
      <Row gutter={[10, 10]}>
        <Col span={6} className='d-flex justify-content-center align-items-center flex-column'>
          <span>{translate('measurements')}</span>
          <Form.Item noStyle name={['stone', 'measurements']}>
            <Input/>
          </Form.Item>
        </Col>

        <Col span={2} className='d-flex justify-content-center align-items-center flex-column'>
          <span>{translate('depht', '%')}</span>
          <Form.Item noStyle name={['stone', 'depht']}>
            <NumericInput/>
          </Form.Item>
        </Col>

        <Col span={2} className='d-flex justify-content-center align-items-center flex-column'>
          <span>{translate('table', '%')}</span>
          <Form.Item noStyle name={['stone', 'table']}>
            <NumericInput/>
          </Form.Item>
        </Col>

        <Col span={2} className='d-flex justify-content-center align-items-center flex-column'>
          <span>{translate('gridle')}</span>
          <Form.Item noStyle name={['stone', 'gridle']}>
            <Input/>
          </Form.Item>
        </Col>

        <Col span={2} className='d-flex justify-content-center align-items-center flex-column'>
          <span>{translate('culet')}</span>
          <Form.Item noStyle name={['stone', 'culet']}>
            <Input/>
          </Form.Item>
        </Col>

        <Col span={2} className='d-flex justify-content-center align-items-center flex-column'>
          <span>{translate('polish')}</span>
          <Form.Item noStyle name={['stone', 'polish']}>
            <Input/>
          </Form.Item>
        </Col>

        <Col span={4} className='d-flex justify-content-center align-items-center flex-column'>
          <span>{translate('synmetry')}</span>
          <Form.Item noStyle name={['stone', 'synmetry']}>
            <Input/>
          </Form.Item>
        </Col>

        <Col span={4} className='d-flex justify-content-center align-items-center flex-column'>
          <span>{translate('fluorecence')}</span>
          <Form.Item noStyle name={['stone', 'fluorecence']}>
            <Input/>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[10, 10]}>
        <InputCard>
          <table className='table-borderless'>
            <tbody>
            <tr>
              <td style={{width: 70}}></td>
              <th className='text-center'>{translate('sales')}</th>
              <th className='text-center'>{translate('mounts')}</th>
              <th className='text-center'>{translate('balance')}</th>
            </tr>
            <tr>
              <td>{translate('number_abbr')}:</td>
              <td>
                <Form.Item noStyle name={['stone', 'saleQuantity']}>
                  <NumericInput/>
                </Form.Item>
              </td>
              <td>
                <Form.Item noStyle name={['stone', 'mountQuantity']}>
                  <NumericInput/>
                </Form.Item>
              </td>
              <td>
                <Form.Item noStyle name={['stone', 'balanceQuantity']}>
                  <NumericInput/>
                </Form.Item>
              </td>
            </tr>

            <tr>
              <td>{translate('carats')}:</td>
              <td>
                <Form.Item noStyle name={['stone', 'saleCarets']}>
                  <NumericInput/>
                </Form.Item>
              </td>
              <td>
                <Form.Item noStyle name={['stone', 'mountCarets']}>
                  <NumericInput/>
                </Form.Item>
              </td>
              <td>
                <Form.Item noStyle name={['stone', 'balanceCarets']}>
                  <NumericInput/>
                </Form.Item>
              </td>
            </tr>
            </tbody>
          </table>
        </InputCard>

        <Col span={8} className={styles['CertTable']}>
          <Row>
            <Form.Item
              label={translate('certification_abbr', 'number_abbr')}
              name={['stone', 'certificateNumber']}
            >
              <Input/>
            </Form.Item>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item label={translate('lab')} name={['stone', 'lab']}>
                <Input/>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                className='ms-2'
                label={translate('date')}
                name={['stone', 'certificateDate']}
              >
                <DatePicker format={dateFormat} style={{width: '100%'}}/>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className={styles['StoneNotes']} gutter={[10, 10]}>
        <Col span={16}>
          <Form.Item label={translate('note')} name={['stone', 'note']}>
            <Input.TextArea rows={2}/>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item noStyle name={['stone', 'certificateImage']}>
            <Uploader
              buttonLabel={translate('upload', 'certificate')}
              fileType='Pdf'
            />
          </Form.Item>
        </Col>
      </Row>


      <Divider className='mb-0'/>

      <Row>
        <Col>
          <ItemFormVrnPackage/>
        </Col>
      </Row>
    </InputCard>
  )
}
